import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { z, ZodType } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PrimaryButton, TextField } from 'components/Common';
import { LoginFormData } from 'data/types/auth';
import { acceptAup, signIn, verifyOtp } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IVerifyOTP } from 'data/types/request';
import { AuthenticationModal } from 'components/Modal';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import TermsAndConditionsModal from 'components/Modal/TermsAndConditionsModal/TermsAndConditionsModal';
import ErrorMessage from 'components/AuthComponent/ErrorMessage/ErrorMessage';
import QrCode from 'components/AuthComponent/common/QrCodePage/QrCode';
import SideImage from '../../components/Common/SideImage/SideImage';

export const loginValidation: ZodType<LoginFormData> = z
    .object({
        email: z
            .string()
            .min(1, { message: 'Email is required' })
            .email({ message: 'Invalid email format' }),
        password: z.string().min(1, { message: 'Password is required' }),
    })
    .required();

const Login = () => {
    const { dispatch } = useAuthContext();
    const [open, setOpen] = useState<boolean>(false);
    const [qrCode, setQrCode] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [isTimeOutError, setIsTimeOutError] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);

    const digitLeftCount = 6 - otp.length;
    const [isLoading, setIsLoading] = useState(false);
    const { control, handleSubmit, reset } = useForm<LoginFormData>({
        resolver: zodResolver(loginValidation),
        defaultValues: {},
    });

    const navigate = useNavigate();
    const localToken = localStorage.getItem('token');

    useEffect(() => {
        if (localToken) {
            return navigate('/operations');
        }

        const storedErrorMessage = localStorage.getItem('authErrorMessage');
        if (storedErrorMessage) {
            const errorObj = JSON.parse(storedErrorMessage);

            if (errorObj) {
                localStorage.removeItem('authErrorMessage');
                showToast(
                    errorObj?.message === 'jwt expired'
                        ? 'This link has expired please contact your admin'
                        : errorObj?.message,
                    'error'
                );
            }
        }
    }, []);

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            email: formData?.email,
            password: formData?.password,
        };
        setIsLoading(true);
        signIn(payload)
            .then((result) => {
                setToken(result?.token);
                // For First time login
                if (!result?.aupStatus) {
                    setIsFirstTimeLogin(true);
                    if (!result?.qr) {
                        setTermsModalOpen(true);
                    }
                } else {
                    setIsFirstTimeLogin(false);
                    setOpen(true);
                }
                setQrCode(result?.qr as string);
                reset({ password: '' });
                showToast('Successfully', 'success');
                setIsLoading(false);
                setIsTimeOutError(false);
                localStorage.removeItem('timeLeft');
            })
            .catch((err) => {
                setIsLoading(false);
                if (err?.errors?.[0]?.code === 'LOGIN_ATTEMPTS_TIMEOUT') {
                    setIsTimeOutError(true);
                    if (!err?.timeLeft) {
                        localStorage.setItem(
                            'timeLeft',
                            String(new Date().getTime())
                        );
                        setTimeLeft(1800);
                    } else {
                        const currentTimestamp = new Date().getTime();
                        const startTimestamp =
                            currentTimestamp - (1800 - err.timeLeft) * 1000;
                        localStorage.setItem(
                            'timeLeft',
                            String(startTimestamp)
                        );
                        setTimeLeft(err?.timeLeft);
                    }
                    return;
                }
                setIsTimeOutError(false);
                localStorage.removeItem('timeLeft');
                const errorMessage =
                    err?.errors?.[0]?.message || err?.message || err;
                showToast(errorMessage || 'something went wrong', 'error');
            });
    });

    const acceptTermsAndPolicy = () => {
        setIsOtpLoading(true);
        acceptAup(token)
            .then((res) => {
                if (res?.isSuccess) {
                    setIsFirstTimeLogin(false);
                }
                if (!qrCode) {
                    setOpen(true);
                }
            })
            .catch((err) => {
                showToast(err?.message, 'error');
            })
            .finally(() => {
                setTermsModalOpen(false);
                setIsOtpLoading(false);
            });
    };

    const onOTPVerify = () => {
        if (!qrCode && isFirstTimeLogin) {
            acceptTermsAndPolicy();
            return;
        }

        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };
        setIsOtpLoading(true);

        if (isFirstTimeLogin) {
            acceptTermsAndPolicy();
        }

        verifyOtp(payload, token)
            .then((result) => {
                if (result?.token) {
                    localStorage.setItem('token', result?.token);
                }
                if (result?.qr) {
                    setQrCode(result?.qr);
                } else {
                    setQrCode('');
                }
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsOtpLoading(false);
                setTermsModalOpen(false);
            });
    };

    const handleTermsModalOpen = () => {
        setTermsModalOpen(true);
    };

    return (
        <>
            {termsModalOpen && (
                <div className="bg-[#F8FBEACC] z-10 flex w-full h-full left-0 items-center absolute justify-center">
                    <TermsAndConditionsModal
                        setTermsModalOpen={setTermsModalOpen}
                        onAccept={onOTPVerify}
                        isLoading={isOtpLoading}
                    />
                </div>
            )}
            <SideImage>
                {qrCode ? (
                    <QrCode
                        disabled={
                            digitLeftCount !== 0 ||
                            (isOtpLoading && !termsModalOpen)
                        }
                        isOtpLoading={isOtpLoading}
                        loading={isOtpLoading && !termsModalOpen}
                        onSubmit={
                            isFirstTimeLogin
                                ? handleTermsModalOpen
                                : onOTPVerify
                        }
                        otp={otp}
                        otpError={otpError}
                        qrCode={qrCode}
                        termsModalOpen={termsModalOpen}
                        setOtp={setOtp}
                    />
                ) : (
                    <div className="h-full w-full flex flex-col justify-center">
                        <div className="flex w-full items-center tracking-tighter text-green-100 font-merri-bold text-[32px]">
                            Let’s get you started
                        </div>
                        <div className="text-base">
                            Enter your credentials below to login
                        </div>
                        <form onSubmit={onSubmit} className="w-full">
                            <div className="flex flex-col pt-12 w-[100%]">
                                <RFTextField
                                    control={control}
                                    name="email"
                                    fullWidth
                                    label="Email address"
                                    type="email"
                                    asterisk
                                />
                                <div className="mt-2">
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                type="password"
                                                asterisk
                                                className={classNames(
                                                    'text-gray-400',
                                                    !fieldState.error?.message
                                                        ? 'text-gray-400 bg-gray-200'
                                                        : 'bg-red-300 border-0 '
                                                )}
                                                isError={
                                                    !!fieldState.error?.message
                                                }
                                                errorMessage={
                                                    fieldState.error?.message ??
                                                    ''
                                                }
                                                iconPosition="right"
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    {isTimeOutError && (
                                        <ErrorMessage
                                            isTimeOutError={isTimeOutError}
                                            setIsTimeOutError={
                                                setIsTimeOutError
                                            }
                                            timeLeft={timeLeft}
                                            setTimeLeft={setTimeLeft}
                                        />
                                    )}
                                    <div className="mt-0 flex w-full items-center justify-end text-[12px]">
                                        Forgot password{' '}
                                        <Link
                                            className="cursor-pointer bg-transparent border-b-0 pl-1 font-merri-bold tracking-tight text-green-200"
                                            to="/forgot-password"
                                        >
                                            Request here
                                        </Link>
                                    </div>
                                </div>
                                <PrimaryButton
                                    type="submit"
                                    name={isLoading ? '' : 'Submit'}
                                    loading={isLoading}
                                    disabled={isLoading}
                                    color="#2E672F"
                                    isDrawerButton
                                    variant="filled"
                                    className="hover:bg-[#2E672F] hover:border-green-600 focus:border-green-600 mt-10 w-full pt-1"
                                />
                            </div>
                        </form>

                        {open && (
                            <AuthenticationModal
                                setIsOpen={setOpen}
                                handleVerifyOTP={
                                    isFirstTimeLogin
                                        ? handleTermsModalOpen
                                        : onOTPVerify
                                }
                                setOtp={setOtp}
                                isOtpLoading={isOtpLoading}
                                otp={otp}
                                otpError={otpError}
                            />
                        )}
                    </div>
                )}
            </SideImage>
        </>
    );
};
export default Login;
