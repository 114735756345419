import React, { ChangeEvent, useEffect, useState } from 'react';
import { InvoiceTable } from './InvoiceTable/InvoiceTable';
import {
    IInvoiceData,
    invoiceFilterOptions,
    IPricingAndBillingsProps,
} from '../Billing.types';
import { Select, SvgIcon } from '../../Common';

const PricingAndBillings = ({
    invoiceData,
    getInvoiceData,
}: IPricingAndBillingsProps) => {
    const [tableCollapsed, setTableCollapsed] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>('ALL');
    const [filteredInvoices, setFilteredInvoices] =
        useState<IInvoiceData[]>(invoiceData);

    useEffect(() => {
        setFilteredInvoices(invoiceData);
    }, [invoiceData]);

    const handleFilterSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const filter = e.target.value;
        setSelectedFilter(filter);
        const filteredData = invoiceData.filter(
            (ele) => ele?.status === filter
        );
        setFilteredInvoices(filter === 'ALL' ? invoiceData : filteredData);
    };

    return (
        <>
            <div className="font-merri-bold text-2xl pb-4">
                Pricing and Billing
            </div>
            {invoiceData.length > 0 ? (
                <>
                    <div className="flex items-center justify-between gap-x-6">
                        <button
                            type="button"
                            aria-label="colllapse"
                            onClick={() => setTableCollapsed(!tableCollapsed)}
                            className="flex items-center px-0 gap-x-4 bg-transparent border-none cursor-pointer"
                        >
                            <SvgIcon
                                icon={
                                    tableCollapsed ? 'ARROW_UP' : 'ARROW_DOWN'
                                }
                                className="stroke-black pb-0.5"
                            />
                            <div className="font-poppins-bold my-8 text-base leading-5">
                                Payment History
                            </div>
                        </button>
                        <Select
                            isGroup
                            isMarginBottom={false}
                            value={selectedFilter}
                            options={[
                                { _id: 'ALL', name: 'Show All' },
                                ...invoiceFilterOptions,
                            ]}
                            handleOnSelect={handleFilterSelect}
                            className="min-w-36 font-poppins-medium !pr-2 !border-2 !border-[#818181] !rounded-xl px-4 !text-xs !h-11"
                        />
                    </div>
                    {!tableCollapsed && (
                        <InvoiceTable
                            pricingData={filteredInvoices}
                            getInvoiceData={getInvoiceData}
                        />
                    )}
                </>
            ) : (
                <div className="text-base">
                    You don't have any plan selected, please select a plan from
                    the price plan below.
                </div>
            )}
        </>
    );
};
export default PricingAndBillings;
