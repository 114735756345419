import { z, ZodType } from 'zod';
import { RegisterFormData } from 'data/types/auth';

export const registerFormValidation: ZodType<RegisterFormData> = z
    .object({
        email: z
            .string({ required_error: 'Email is required' })
            .min(1, { message: 'Email is required' })
            .email({ message: 'Invalid email format' }),
        firstName: z
            .string({ required_error: 'First name is required' })
            .min(1, { message: 'First name is required' }),
        lastName: z
            .string({ required_error: 'Last name is required' })
            .min(1, { message: 'Last name is required' }),
        company: z
            .string({ required_error: 'Company name is required' })
            .min(1, { message: 'Company Name is required' }),
        billingCurrency: z
            .string({ required_error: 'Billing currency is required' })
            .trim()
            .min(1, { message: 'Billing currency is required' }),
        country: z
            .string({ required_error: 'Country is required' })
            .trim()
            .min(1, { message: 'Country is required' }),
        isReceivedOffers: z.boolean(),
        policy: z
            .boolean({
                required_error:
                    'You must agree to the terms and privacy policy',
            })
            .refine((value) => value, {
                message: 'You must agree to the terms and privacy policy',
            }),
    })
    .required();
