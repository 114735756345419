import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResetPassword } from 'components/AuthComponent';
import { IVerifyOTP } from 'data/types/request';
import { createNewPassword, acceptAup, verifyOtp } from 'services/api/api';
import { IPasswordData } from 'data/types/auth';
import { showToast } from 'data/utils/toast';
import { AuthenticationModal } from 'components/Modal';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import { z, ZodType } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import TermsAndConditionsModal from 'components/Modal/TermsAndConditionsModal/TermsAndConditionsModal';
import SideImage from 'components/Common/SideImage/SideImage';
import QrCode from 'components/AuthComponent/common/QrCodePage/QrCode';

export const formValidation: ZodType<IPasswordData> = z
    .object({
        password: z
            .string({ required_error: 'Password is required' })
            .min(12, {
                message: 'Password must be at least 12 characters long',
            })
            .regex(/[A-Z]/, {
                message: 'Password must include at least one uppercase letter',
            })
            .regex(/[a-z]/, {
                message: 'Password must include at least one lowercase letter',
            })
            .regex(/\d/, {
                message: 'Password must include at least one number',
            })
            .regex(/[^A-Za-z0-9]/, {
                message:
                    'Password must include at least one special character (e.g., !, @, #, $, %)',
            }),
        confirmPassword: z.string({
            required_error: 'Confirm Password is required',
        }),
    })
    .refine((data) => data.password === data.confirmPassword, {
        message: 'Passwords do not match',
        path: ['confirmPassword'],
    })
    .refine(
        (data) =>
            data.password !== undefined && data.confirmPassword !== undefined,
        {
            message: 'Both Password and Confirm Password are required',
            path: ['password', 'confirmPassword'],
        }
    );

const NewAdminPassword = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const { dispatch } = useAuthContext();
    const [otpError, setOtpError] = useState<string>('');
    const [qrCode, setQrCode] = useState('');
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<IPasswordData>({
        resolver: zodResolver(formValidation),
        defaultValues: {},
    });

    const verifyUser = JSON.parse(localStorage.getItem('user') as string);

    const digitLeftCount = 6 - otp.length;

    useEffect(() => localStorage.removeItem('token'), []);

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            password: formData?.password,
            email: verifyUser.email,
        };
        setIsLoading(true);
        createNewPassword(payload)
            .then((result) => {
                setQrCode(result?.qr);
                if (result?.qr) {
                    setIsFirstTimeLogin(true);
                } else {
                    setIsFirstTimeLogin(false);
                }
                localStorage.setItem('user', JSON.stringify(result.user));
                setToken(result.token);
                showToast('Your password created successfully', 'success');
                reset({});
                setOpen(true);
            })
            .catch((err) => {
                showToast(
                    err || 'Your password not created successfully',
                    'success'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    const onVerifyOtp = () => {
        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };
        setIsOtpLoading(true);

        if (isFirstTimeLogin) {
            acceptAup(token)
                .then((res) => {
                    if (res?.isSuccess) {
                        setIsFirstTimeLogin(false);
                    }
                })
                .catch((err) => {
                    showToast(err?.message, 'error');
                });
        }

        verifyOtp(payload, token)
            .then((result) => {
                localStorage.setItem('token', result.token);
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsOtpLoading(false);
                setTermsModalOpen(false);
            });
    };

    const handleTermsModalOpen = () => {
        setTermsModalOpen(true);
    };

    return (
        <>
            {termsModalOpen && (
                <div className="bg-[#F8FBEACC] z-10 flex w-full h-full items-center absolute justify-center">
                    <TermsAndConditionsModal
                        setTermsModalOpen={setTermsModalOpen}
                        onAccept={onVerifyOtp}
                        isLoading={isOtpLoading}
                    />
                </div>
            )}
            <SideImage>
                {qrCode ? (
                    <QrCode
                        disabled={
                            digitLeftCount !== 0 ||
                            (isOtpLoading && !termsModalOpen)
                        }
                        isOtpLoading={isOtpLoading}
                        loading={isOtpLoading && !termsModalOpen}
                        onSubmit={
                            isFirstTimeLogin
                                ? handleTermsModalOpen
                                : onVerifyOtp
                        }
                        otp={otp}
                        otpError={otpError}
                        qrCode={qrCode}
                        termsModalOpen={termsModalOpen}
                        setOtp={setOtp}
                    />
                ) : (
                    <>
                        <ResetPassword
                            handleSubmit={onSubmit}
                            control={control}
                            isLoading={isLoading}
                            title="Create Your Password"
                            description="Enter your password to complete the onboarding process"
                            btnName="Set Password"
                        />
                        {open && (
                            <AuthenticationModal
                                setIsOpen={setOpen}
                                handleVerifyOTP={onVerifyOtp}
                                isOtpLoading={isOtpLoading}
                                setOtp={setOtp}
                                otp={otp}
                                otpError={otpError}
                            />
                        )}
                    </>
                )}
            </SideImage>
        </>
    );
};

export default NewAdminPassword;
