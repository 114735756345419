import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IPasswordData } from 'data/types/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { formValidation } from 'pages/auth/NewAdminPassword';
import SideImage from 'components/Common/SideImage/SideImage';
import { acceptAup, createNewPassword, verifyOtp } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import TermsAndConditionsModal from 'components/Modal/TermsAndConditionsModal/TermsAndConditionsModal';
import { IVerifyOTP } from 'data/types/request';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import { ResetPassword } from '../../index';
import QrCode from '../../common/QrCodePage/QrCode';

const CreatePasswordForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [qrCode, setQrCode] = useState('');
    const [token, setToken] = useState<string>('');
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
    const [otp, setOtp] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const { dispatch } = useAuthContext();
    const navigate = useNavigate();

    const { control, handleSubmit, reset } = useForm<IPasswordData>({
        resolver: zodResolver(formValidation),
        defaultValues: {},
    });

    const verifyUser = JSON.parse(localStorage.getItem('user') as string);

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        if (!verifyUser?.email) {
            showToast('No email found', 'error');
        }
        const payload = {
            password: formData?.password,
            email: verifyUser?.email,
        };
        setIsLoading(true);
        createNewPassword(payload)
            .then((result) => {
                setQrCode(result?.qr);
                localStorage.setItem('user', JSON.stringify(result.user));
                if (result?.qr) {
                    setIsFirstTimeLogin(true);
                } else {
                    setIsFirstTimeLogin(false);
                }
                setToken(result.token);
                showToast('Your password created successfully', 'success');
                reset({});
            })
            .catch((err) => {
                const errMessage =
                    err.message ?? 'Your password not created successfully';
                showToast(errMessage, 'error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    const onVerifyOtp = () => {
        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };
        setIsOtpLoading(true);

        if (isFirstTimeLogin) {
            acceptAup(token)
                .then((res) => {
                    if (res?.isSuccess) {
                        setIsFirstTimeLogin(false);
                    }
                })
                .catch((err) => {
                    showToast(err?.message, 'error');
                });
        }

        verifyOtp(payload, token)
            .then((result) => {
                localStorage.setItem('token', result.token);
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                        isRegisterUser: true,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsOtpLoading(false);
                setTermsModalOpen(false);
            });
    };

    const handleTermsModalOpen = () => {
        setTermsModalOpen(true);
    };

    const digitLeftCount = 6 - otp.length;

    return (
        <>
            {termsModalOpen && (
                <div className="bg-[#F8FBEACC] z-10 flex w-full h-full items-center absolute justify-center">
                    <TermsAndConditionsModal
                        setTermsModalOpen={setTermsModalOpen}
                        onAccept={onVerifyOtp}
                        isLoading={isOtpLoading}
                    />
                </div>
            )}

            <SideImage>
                <div className="fixed right-8 top-8 flex gap-2 items-center">
                    <div className="text-[#818181] text-sm leading-[18px] font-poppins-bold">
                        Steps
                    </div>
                    <div className="w-6 h-2  bg-[#8FB131]" />
                    <div className="w-6 h-2  bg-[#8FB131]" />
                    <div className="w-6 h-2  bg-[#8FB131]" />
                </div>
                {qrCode ? (
                    <QrCode
                        disabled={
                            digitLeftCount !== 0 ||
                            (isOtpLoading && !termsModalOpen)
                        }
                        isOtpLoading={isOtpLoading}
                        loading={isOtpLoading && !termsModalOpen}
                        onSubmit={
                            isFirstTimeLogin
                                ? handleTermsModalOpen
                                : onVerifyOtp
                        }
                        otp={otp}
                        otpError={otpError}
                        qrCode={qrCode}
                        termsModalOpen={termsModalOpen}
                        setOtp={setOtp}
                    />
                ) : (
                    <ResetPassword
                        handleSubmit={onSubmit}
                        control={control}
                        isLoading={isLoading}
                        title="Create Password"
                        description="For your security, please create a strong and memorable password."
                        btnName="Save and Continue"
                    />
                )}
            </SideImage>
        </>
    );
};

export default CreatePasswordForm;
