import React from 'react';
import classNames from 'classnames';
import OtpInput from 'react-otp-input';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { PrimaryButton } from '../../../Common';

interface IQrCodeProps {
    qrCode: string;
    otp: string;
    setOtp: React.Dispatch<React.SetStateAction<string>>;
    disabled: boolean;
    loading: boolean;
    onSubmit: () => void;
    otpError: string;
    isOtpLoading: boolean;
    termsModalOpen?: boolean;
}

const QrCode = ({
    qrCode,
    otp,
    setOtp,
    disabled,
    loading = false,
    onSubmit,
    otpError,
    isOtpLoading,
    termsModalOpen,
}: IQrCodeProps) => {
    const digitLeftCount = 6 - otp.length;

    const getBorderStyle = (value: number) => {
        if (otpError && otp.length < 6) {
            return '1px solid red';
        }
        if (otp && value) {
            return '2px solid #9ED79F';
        }
        return 'none';
    };

    const getButtonName = () => {
        if (isOtpLoading && !termsModalOpen) {
            return '';
        }
        if (digitLeftCount === 0) {
            return `Let's go`;
        }
        return `${digitLeftCount} Digit Left`;
    };

    return (
        <div className="mx-auto flex flex-col items-start justify-center w-[500px] max-[900px]:w-[300px]">
            <div className="flex flex-col items-center gap-5">
                <div>
                    <SvgIcon
                        className="mb-11 flex flex-col items-center"
                        icon="GOOGLE_LOGO"
                    />
                    <div className="flex flex-col items-center gap-4">
                        <div className="mt-1 flex justify-center text-2xl tracking-tighter text-green-700 font-merri-bold">
                            Two-Factor Authentication - A Breeze!
                        </div>
                        <div className="text-center text-base leading-5 tracking-tight font-poppins-regular">
                            Download the free{' '}
                            <span className="text-green-200 font-poppins-bold">
                                Google Authenticator{' '}
                            </span>{' '}
                            App, add a new account, then scan the Barcode to set
                            up your account.
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full flex-col items-center gap-4 mt-5">
                <img src={qrCode} alt="code" height="230px" width="230px" />
                <OtpInput
                    value={otp}
                    onChange={(value: string) => setOtp(value)}
                    numInputs={6}
                    renderSeparator={<span style={{ marginLeft: '13px' }} />}
                    renderInput={(props: any) => (
                        <input
                            id="inputOtp"
                            {...props}
                            style={{
                                width: '64px',
                                height: '72px',
                                color: 'text-green-600',
                                background: '#F7F7F7',
                                border: getBorderStyle(props.value),
                                boxShadow:
                                    otp && props.value
                                        ? '0 0 0 2px #9ED79F, 0 0 0 6px #C9E9C9'
                                        : 'none',
                                borderRadius: 15,
                                fontSize: '30px',
                                fontWeight: 500,
                                textAlign: 'center',
                                outline: props.value ? '3px solid #9ED79F' : '',
                            }}
                        />
                    )}
                />
            </div>
            <PrimaryButton
                onClick={onSubmit}
                type="submit"
                disabled={disabled}
                loading={loading}
                name={getButtonName()}
                color="#2E672F"
                variant="filled"
                isDrawerButton
                className={classNames(
                    'flex justify-center items-center w-full h-10 rounded-xl text-base !font-extrabold pt-1 mt-9',
                    digitLeftCount !== 0
                        ? '!bg-zinc-300 !text-gray-400'
                        : 'bg-green-700 text-white'
                )}
            />
        </div>
    );
};

export default QrCode;
