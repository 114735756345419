import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';
import { IdleTimerProvider } from 'react-idle-timer';
import Login from 'pages/auth/Login';
import ValidateSignupToken from 'pages/auth/ValidateSignupToken';
import ValidateResetToken from 'pages/auth/ValidateResetToken';
import ForgotPasswordPage from 'pages/auth/ForgotPassword';
import ResetPasswordPage from 'pages/auth/ResetPassword';
import NewAdminPassword from 'pages/auth/NewAdminPassword';
import { Loader } from 'components/Common';
import 'react-toastify/dist/ReactToastify.css';
import SessionTimeoutModal from 'components/Modal/SessionTimeoutModal/SessionTimeoutModal';
import Register from 'pages/auth/Register';
import CreatePasswordForm from 'components/AuthComponent/Registration/CreatePasswordForm/CreatePasswordForm';
import ValidateRegisterToken from 'pages/auth/ValidateRegisterToken';
import './App.css';
import { useAuthContext } from './context/Auth/AuthContext';
import { RouteList } from './routes/Routes';

function App(): JSX.Element {
    const { auth } = useAuthContext();
    const [routes, setRoutes] = useState<JSX.Element>();
    const [isUserTimedOut, setIsUserTimedOut] = useState(false);

    const updateRoutes = (isUserLoggedIn: boolean | null) => {
        if (isUserLoggedIn === null) {
            return (
                <div className="flex items-center justify-center w-[100vw] h-[100vh]">
                    {' '}
                    <Loader />
                </div>
            );
        }

        if (isUserLoggedIn) {
            return <RouteList />;
        }
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Login />} />
                <Route path="/registration" element={<Register />} />
                <Route path="/set-password" element={<CreatePasswordForm />} />
                <Route
                    path="/validate-signup-token"
                    element={<ValidateSignupToken />}
                />
                <Route
                    path="/validate-register-token"
                    element={<ValidateRegisterToken />}
                />
                <Route
                    path="/validate-reset-token"
                    element={<ValidateResetToken />}
                />
                <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route
                    path="/create-new-password"
                    element={<NewAdminPassword />}
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        );
    };

    useEffect(() => {
        setRoutes(updateRoutes(auth?.isLoggedIn));
    }, [auth?.isLoggedIn]);

    const handleOnIdle = () => {
        if (localStorage.token) {
            localStorage.clear();
            setIsUserTimedOut(true);
        }
    };

    return (
        <IdleTimerProvider
            timeout={15 * 60 * 1000}
            onIdle={handleOnIdle}
            debounce={500}
        >
            {isUserTimedOut && (
                <SessionTimeoutModal setIsUserTimedOut={setIsUserTimedOut} />
            )}
            {routes}
            <ToastContainer
                style={{
                    background: 'transparent',
                    marginLeft: '130px',
                }}
                toastStyle={{
                    padding: '20px',
                }}
                position="top-center"
                transition={Bounce}
                limit={3}
            />
        </IdleTimerProvider>
    );
}

export default App;
