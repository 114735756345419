import cc from 'currency-codes';
import { ICountry } from 'components/OnBoardCashier/OnBoardCashier.type';
import { IBrandsData } from 'hooks/useFilteredBrandData';

export enum PaymentProviderConstant {
    TRUST = 'TRUST',
    CLEO = 'CLEO',
    PAYMODUM = 'PAYMODUM',
    BRITE = 'BRITE',
    ONBOARD_CASHIER = 'ONBOARD_CASHIER',
}
export enum PaymentProviderConstantLabel {
    TRUST = 'Trust Payment',
    CLEO = 'Cleo',
    PAYMODUM = 'PayModum',
    BRITE = 'Brite',
}
export enum TransactionStatusLabel {
    ACCEPT = 'Accept',
    REJECT = 'Reject',
    HOLD = 'Hold',
}
export enum TransactionStatusConstant {
    ACCEPT = 'ACCEPT',
    REJECT = 'REJECT',
    HOLD = 'HOLD',
}

export const TransactionStatus = [
    {
        _id: TransactionStatusConstant.ACCEPT,
        name: TransactionStatusLabel.ACCEPT,
    },
    {
        _id: TransactionStatusConstant.REJECT,
        name: TransactionStatusLabel.REJECT,
    },
    {
        _id: TransactionStatusConstant.HOLD,
        name: TransactionStatusLabel.HOLD,
    },
];

export const PaymentProvider = [
    {
        _id: PaymentProviderConstant.TRUST,
        name: PaymentProviderConstantLabel.TRUST,
    },
    {
        _id: PaymentProviderConstant.CLEO,
        name: PaymentProviderConstantLabel.CLEO,
    },
    {
        _id: PaymentProviderConstant.PAYMODUM,
        name: PaymentProviderConstantLabel.PAYMODUM,
    },
    {
        _id: PaymentProviderConstant.BRITE,
        name: PaymentProviderConstantLabel.BRITE,
    },
];

export enum CardConstant {
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
    DINERS_CLUB = 'DINERS_CLUB',
    DISCOVER = 'DISCOVER',
    JCB = 'JCB',
    UNIONPAY = 'UNIONPAY',
    MAESTRO = 'MAESTRO',
    ELO = 'ELO',
    MIR = 'MIR',
    HIPER = 'HIPER',
    HIPERCARD = 'HIPERCARD',
}
export enum CardConstantLabel {
    VISA = 'visa',
    MASTERCARD = 'mastercard',
    AMERICAN_EXPRESS = 'american-express',
    DINERS_CLUB = 'diners-club',
    DISCOVER = 'discover',
    JCB = 'jcb',
    UNIONPAY = 'unionpay',
    MAESTRO = 'maestro',
    ELO = 'elo',
    MIR = 'mir',
    HIPER = 'hiper',
    HIPERCARD = 'hipercard',
}

export const CardType = [
    { _id: CardConstant.MASTERCARD, name: CardConstantLabel.MASTERCARD },
    {
        _id: CardConstant.VISA,
        name: CardConstantLabel.VISA,
    },
    {
        _id: CardConstant.AMERICAN_EXPRESS,
        name: CardConstantLabel.AMERICAN_EXPRESS,
    },
    {
        _id: CardConstant.DINERS_CLUB,
        name: CardConstantLabel.DINERS_CLUB,
    },
    {
        _id: CardConstant.DISCOVER,
        name: CardConstantLabel.DISCOVER,
    },
    {
        _id: CardConstant.JCB,
        name: CardConstantLabel.JCB,
    },
    {
        _id: CardConstant.UNIONPAY,
        name: CardConstantLabel.UNIONPAY,
    },
    {
        _id: CardConstant.MAESTRO,
        name: CardConstantLabel.MAESTRO,
    },
    {
        _id: CardConstant.ELO,
        name: CardConstantLabel.ELO,
    },
    {
        _id: CardConstant.MIR,
        name: CardConstantLabel.MIR,
    },
    {
        _id: CardConstant.HIPER,
        name: CardConstantLabel.HIPER,
    },
    {
        _id: CardConstant.HIPERCARD,
        name: CardConstantLabel.HIPERCARD,
    },
];

export const generateRandomData = (
    type: PaymentProviderConstant,
    reset: any,
    countries: ICountry[],
    brands?: IBrandsData[]
) => {
    const getRandomNumber = (min: number, max: number) =>
        Math.floor(Math.random() * (max - min + 1)) + min;

    const getRandomDate = (startYear: number) =>
        `${String(getRandomNumber(1, 12)).padStart(2, '0')}-${String(
            getRandomNumber(1, 28)
        ).padStart(2, '0')}-${getRandomNumber(startYear, startYear + 5)}`;

    const getRandomTags = () => {
        const tags = [
            'Finance',
            'Health',
            'Education',
            'Technology',
            'Travel',
            'Food',
            'Entertainment',
            'Lifestyle',
        ];
        const shuffledTags = [...tags].sort(() => 0.5 - Math.random());
        return shuffledTags.slice(0, 2);
    };

    const brandIds = brands
        ? brands.map((brand: { _id: string }) => brand._id)
        : '';

    const commonData = {
        firstName: ['Idona', 'Alex', 'Taylor', 'Jordan'][
            Math.floor(Math.random() * 4)
        ],
        lastName: ['Barr', 'Smith', 'Doe', 'Johnson'][
            Math.floor(Math.random() * 4)
        ],
        dob: getRandomDate(1960),
        kycVerified: Math.random() > 0.5,
        blocked: Math.random() > 0.5,
        transactionCount: '5',
        brandId: brands
            ? brandIds[Math.floor(Math.random() * brandIds.length)]
            : '',
        // countryCode: ['CA', 'US', 'UK', 'AU'][Math.floor(Math.random() * 4)],
        currency: cc.data[Math.floor(Math.random() * cc.data.length)].code,
        bonusCode: getRandomNumber(1000, 9999).toString(),
        userBalance: Math.floor(Math.random() * 100000),
        baseamount: String(Math.floor(Math.random() * 10000)),
        phone: getRandomNumber(1000000000, 9999999999).toString(),
        gender: ['Male', 'Female', 'Other'][Math.floor(Math.random() * 3)],
        email: `user${Math.floor(Math.random() * 1000)}@mailinator.com`,
        emailVerification: `user${Math.floor(
            Math.random() * 1000
        )}@mailinator.com`,
        verificationDate: getRandomDate(2023), // Random verification date
        partnerName: ['Montana Bullock', 'Sam Green', 'Jordan White'][
            Math.floor(Math.random() * 3)
        ],
        tags: getRandomTags(),
        comment: [
            'The payment process was smooth and easy.',
            'I had an issue with my payment method. Can you help?',
            'Is there a way to track my payment status?',
            'The payment confirmation took longer than expected.',
            'Are there any additional charges for this transaction?',
            'Can I change my payment method after placing the order?',
            'Thanks for the quick payment processing!',
            'I need a refund. What is the process?',
        ][Math.floor(Math.random() * 8)],
        address: {
            line1: `Random Street ${Math.floor(Math.random() * 100)}`,
            line2: `Apt ${Math.floor(Math.random() * 10)}`,
            postalCode: `${Math.floor(Math.random() * 10000)
                .toString()
                .padStart(6, '0')}`,
            state: ['Ontario', 'Quebec', 'British Columbia'][
                Math.floor(Math.random() * 3)
            ],
            country:
                countries?.[Math.floor(Math.random() * countries.length)]?.name
                    ?.common,
            city: ['Toronto', 'Vancouver', 'Montreal'][
                Math.floor(Math.random() * 3)
            ],
        },
    };

    const filteredCountryCode: any = countries?.find(
        (country: ICountry) =>
            country?.name?.common === commonData.address.country
    );

    let specificData = {};

    switch (type) {
        case PaymentProviderConstant.TRUST:
            specificData = {
                currencyCode: cc.code(commonData.currency)?.number,
                countryCode: filteredCountryCode?.cca2,
                card: {
                    expiry: `${String(getRandomNumber(1, 12)).padStart(
                        2,
                        '0'
                    )}/${getRandomNumber(2024, 2029)}`,
                    pan: '4111111111111111',
                    cvv: '123',
                    name_on_card: ['Idona', 'Alex', 'Taylor', 'Jordan'][
                        Math.floor(Math.random() * 4)
                    ],
                },
            };
            break;
        case PaymentProviderConstant.CLEO:
        case PaymentProviderConstant.BRITE:
            specificData = {
                currencyCode: cc.code(commonData.currency)?.number,
                countryCode: filteredCountryCode?.cca2,
            };
            break;
        case PaymentProviderConstant.PAYMODUM:
            specificData = {
                currencyCode: cc.code(commonData.currency)?.number,
                countryCode: filteredCountryCode?.cca2,
                card: {
                    expiry: `${String(getRandomNumber(1, 12)).padStart(
                        2,
                        '0'
                    )}/${getRandomNumber(2024, 2029)}`,
                    pan: '4111111111111111',
                    cvv: '123',
                    paymentMethod: 'VISA',
                    name_on_card: ['Idona', 'Alex', 'Taylor', 'Jordan'][
                        Math.floor(Math.random() * 4)
                    ],
                },
            };
            break;
        default:
            throw new Error('Invalid user type');
    }

    const userData = { ...commonData, ...specificData };
    reset(userData);
};

export enum BILLING_INVOICE_CURRENCY {
    GBP = 'GBP',
    USD = 'USD',
    EUR = 'EUR',
}

export const billingCurrencyOptions = [
    {
        _id: '',
        name: 'Select Billing currency',
    },
    {
        id: BILLING_INVOICE_CURRENCY.USD,
        name: BILLING_INVOICE_CURRENCY.USD,
    },
    {
        id: BILLING_INVOICE_CURRENCY.GBP,
        name: BILLING_INVOICE_CURRENCY.GBP,
    },
    {
        id: BILLING_INVOICE_CURRENCY.EUR,
        name: BILLING_INVOICE_CURRENCY.EUR,
    },
];
