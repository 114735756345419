import React, { useState } from 'react';
import SideImage from 'components/Common/SideImage/SideImage';
import VerifyEmailAddress from 'components/AuthComponent/Registration/VerifyEmailAddress/VerifyEmailAddress';
import RegistrationForm from 'components/AuthComponent/Registration/RegistrationForm/RegistrationForm';

const Register = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');

    const getComponent = () => {
        switch (step) {
            case 1:
                return (
                    <RegistrationForm setStep={setStep} setEmail={setEmail} />
                );
            case 2:
                return <VerifyEmailAddress email={email} />;
            default:
                return (
                    <RegistrationForm setStep={setStep} setEmail={setEmail} />
                );
        }
    };

    return (
        <div>
            <div className="fixed right-8 top-8 flex gap-2 items-center">
                <div className="text-[#818181] text-sm leading-[18px] font-poppins-bold">
                    Steps
                </div>
                {[1, 2, 3].map((index) => (
                    <div
                        key={index}
                        className={`w-6 h-2 ${
                            step >= index ? 'bg-[#8FB131]' : 'bg-[#F5F5F5]'
                        }`}
                    />
                ))}
            </div>

            <SideImage>{getComponent()}</SideImage>
        </div>
    );
};

export default Register;
