import React, { useEffect, useState } from 'react';
import { SvgIcon, TextField } from 'components/Common';
import classNames from 'classnames';
import { createPaymentProvider, updatePaymentProvider } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { IAccountManagerHeaderProps } from '../../configuration.type';

const AccountManagerHeader = ({
    accounts,
    activeAccount,
    setActiveAccount,
    initialFormValues,
    fetchConfigurationList,
    selectedPspDataLength = 0,
    selectedCompanyId,
    pspType,
}: IAccountManagerHeaderProps) => {
    const [addAccountModal, setAddAccountModal] = useState(false);
    const [addAccountLoading, setAddAccountLoading] = useState(false);
    const [testAccountModal, setTestAccountModal] = useState(false);
    const [testAccountLoading, setTestAccountLoading] = useState(false);

    const handleAddAccount = async () => {
        const { _id, ...newAccount } = initialFormValues;
        const payload = {
            type: activeAccount?.type || pspType,
            companyId: activeAccount?.companyId || selectedCompanyId,
            ...newAccount,
        };
        setAddAccountLoading(true);
        try {
            const response = await createPaymentProvider(payload);
            setActiveAccount(response.response);
            showToast('Account added successfully', 'success');
        } catch (err: any) {
            showToast(err.message, 'error');
        } finally {
            setAddAccountLoading(false);
            setAddAccountModal(false);
        }
        fetchConfigurationList();
    };

    const date = new Date(activeAccount?.updatedAt);

    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(
        date.getMonth() + 1
    ).padStart(2, '0')}/${date.getFullYear()} at ${String(
        date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

    const handleAccountUpdate = async (key: string, value: boolean) => {
        const payload = {
            type: activeAccount.type,
            companyId: activeAccount.companyId,
            ...activeAccount,
            [key]: value,
        };
        setTestAccountLoading(true);
        try {
            await updatePaymentProvider(payload, activeAccount?._id);
            showToast('successfully updated', 'success');
            fetchConfigurationList();
        } catch (err: any) {
            showToast(err.message, 'error');
        } finally {
            setTestAccountLoading(false);
            setTestAccountModal(false);
        }
    };

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            if (!activeAccount) {
                setActiveAccount(accounts[0]);
            } else {
                const filteredActiveAccount = accounts.find(
                    (account) => account._id === activeAccount._id
                );

                if (!filteredActiveAccount) return;
                setActiveAccount(filteredActiveAccount);
            }
        }
    }, [accounts]);

    return (
        <>
            {addAccountModal && (
                <ConfirmationModal
                    onConfirmation={handleAddAccount}
                    setIsModalOpen={setAddAccountModal}
                    isLoading={addAccountLoading}
                    message={`Are you sure you want to add a new account to ${
                        activeAccount?.type as string
                    }?`}
                    className="!z-[111]"
                />
            )}
            {testAccountModal && (
                <ConfirmationModal
                    onConfirmation={() => {
                        handleAccountUpdate(
                            'isTestAccount',
                            !activeAccount?.isTestAccount
                        );
                    }}
                    setIsModalOpen={setTestAccountModal}
                    isLoading={testAccountLoading}
                    message={`${
                        activeAccount?.isTestAccount
                            ? `Are you sure you want to remove "${activeAccount.accountName}" as a test account?`
                            : `Are you sure you want to mark "${activeAccount.accountName}" as a test account?`
                    }`}
                    className="!z-[111]"
                />
            )}

            <div className="flex items-center gap-2 pt-1 pb-4">
                {activeAccount?.updatedAt && (
                    <>
                        <SvgIcon icon="CLOCK_ICON_SMALL" />
                        <div className="text-xs text-[#383838] pb-0.5">
                            Updated: {formattedDate}
                        </div>
                    </>
                )}
            </div>
            <div className="flex items-center gap-4 flex-wrap">
                {accounts?.map((ele) => (
                    <div key={ele?._id}>
                        <button
                            type="button"
                            onClick={() => {
                                setActiveAccount(ele);
                            }}
                            title={ele?.accountName}
                            className={`cursor-pointer flex w-[107px] justify-center items-center rounded-xl border-none h-8 ${
                                activeAccount?._id === ele?._id
                                    ? 'bg-[#DEECAA]'
                                    : 'bg-white'
                            }`}
                        >
                            <span className="truncate flex items-center w-20 justify-center gap-1 pt-0.5">
                                {ele?.isDefaultAccount && (
                                    <div className="pt-[3px]">
                                        <SvgIcon icon="CHECK_FILL" />
                                    </div>
                                )}
                                {ele?.isDeActivatedAccount && (
                                    <div className="pt-[3px]">
                                        <SvgIcon icon="BLOCKED_ICON" />
                                    </div>
                                )}
                                <span className="truncate font-merri-bold text-xs">
                                    {ele?.accountName ?? 'Account Name'}
                                </span>
                            </span>
                        </button>
                    </div>
                ))}

                <button
                    type="button"
                    onClick={() => setAddAccountModal(true)}
                    className="text-[#2E672F] bg-transparent  cursor-pointer box-border rounded-xl font-merri-bold text-xs border-none w-[106px] h-8"
                >
                    + Add Account
                </button>
            </div>
            {selectedPspDataLength > 0 && (
                <div className="flex justify-end mt-3 mb-1 items-center gap-2">
                    <button
                        type="button"
                        onClick={() => setTestAccountModal(true)}
                        disabled={
                            activeAccount?.isDefaultAccount ||
                            activeAccount?.isDeActivatedAccount
                        }
                        className={classNames(
                            'flex items-center gap-1 bg-transparent border-none',
                            activeAccount?.isDefaultAccount ||
                                activeAccount?.isDeActivatedAccount
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                        )}
                    >
                        <TextField
                            isCheckbox
                            type="checkbox"
                            className={classNames(
                                activeAccount?.isDefaultAccount ||
                                    activeAccount?.isDeActivatedAccount
                                    ? 'checkmark__disabled'
                                    : 'cursor-pointer'
                            )}
                            checked={activeAccount?.isTestAccount}
                        />
                        <div className="text-xs text-[#383838]">
                            Payment provider not needed
                        </div>
                    </button>
                    <button
                        type="button"
                        onClick={() => setTestAccountModal(true)}
                        disabled={activeAccount?.isDefaultAccount}
                        className="flex items-center gap-1 cursor-pointer bg-transparent border-none"
                    >
                        <SvgIcon icon="RESET_ICON" className="pt-1" />
                        <div className="font-merri-bold text-[#2E672F]">
                            Test Account
                        </div>
                    </button>
                </div>
            )}
        </>
    );
};

export default AccountManagerHeader;
