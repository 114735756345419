import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { z, ZodType } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Success from 'assets/images/Success.svg';
import { forgotPassword } from 'services/api/api';
import { IForgotPasswordRes } from 'data/types/response';
import { IForgotPasswordReq } from 'data/types/request';
import { showToast } from 'data/utils/toast';
import { PrimaryButton } from 'components/Common';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import SideImage from 'components/Common/SideImage/SideImage';

export const forgotPasswordValidation: ZodType<IForgotPasswordReq> = z
    .object({
        email: z.string().email({ message: 'Email is required' }),
    })
    .required();

const ResetPasswordPage = () => {
    const [isForgotPasswordData, setIsForgotPasswordData] =
        useState<IForgotPasswordReq | null>();
    const [isRecoveryLink, setIsRecoveryLink] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<IForgotPasswordReq>({
        resolver: zodResolver(forgotPasswordValidation),
        defaultValues: {},
    });

    const onSubmit = handleSubmit((formData) => {
        setIsLoading(true);
        forgotPassword(formData)
            .then((result: IForgotPasswordRes) => {
                showToast(result.message, 'success');
                setIsForgotPasswordData(formData);
                reset({});
                setIsRecoveryLink(true);
            })
            .catch((err) => {
                setIsRecoveryLink(false);
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    const handleOnSubmit = () => {
        if (isForgotPasswordData?.email) {
            setIsLoading(true);

            forgotPassword(isForgotPasswordData)
                .then((result: IForgotPasswordRes) => {
                    showToast(result.message, 'success');
                })
                .catch((err) => {
                    setIsRecoveryLink(false);
                    showToast(err.message, 'error');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <SideImage>
            {isRecoveryLink ? (
                <div className="flex flex-col justify-center items-center mx-auto h-full">
                    <img
                        src={Success}
                        className="loginLogo"
                        alt="logo"
                        width="75px"
                        height="75px"
                    />
                    <div className="flex items-center text-[32px] justify-center text-green-100 font-poppins-bold w-full pt-5">
                        Recovery email sent
                    </div>
                    <div className="text-sm font-base">
                        A recovery link has been sent to
                    </div>
                    <Link to="/" className="text-sm font-medium	text-[#499f6d]">
                        {`${isForgotPasswordData?.email as string}`}
                    </Link>
                    <div className="text-sm font-base">
                        {' '}
                        Please follow the link and reset your password
                    </div>

                    <PrimaryButton
                        onClick={() => handleOnSubmit()}
                        type="submit"
                        loading={isLoading}
                        disabled={isLoading}
                        name={isLoading ? '' : ' Resend  recovery link'}
                        color="#2E672F"
                        variant="filled"
                        isDrawerButton
                        className="mt-10 w-full p-1 font-medium"
                    />
                </div>
            ) : (
                <div className="flex flex-col justify-center h-full">
                    <div className="flex items-center text-[32px] text-green-100 font-merri-bold tracking-tighter w-full">
                        Forgot Password{' '}
                    </div>
                    <div className="text-[16px] w-full font-poppins-medium tracking-tight pt-1">
                        Don't fret! We understand forgetting passwords happens
                        occasionally. Enter your email address and we'll send
                        you a link to recover your password.{' '}
                    </div>
                    <form className="w-full" onSubmit={onSubmit}>
                        <div className="flex flex-col w-[100%] pt-12">
                            <RFTextField
                                control={control}
                                name="email"
                                fullWidth
                                label="Email address"
                                type="email"
                                asterisk
                            />
                            <PrimaryButton
                                type="submit"
                                loading={isLoading}
                                disabled={isLoading}
                                name={isLoading ? '' : ' Send recovery link'}
                                isDrawerButton
                                color="#2E672F"
                                variant="filled"
                                className="mt-10 w-full p-1 font-medium"
                            />

                            <div className="flex justify-start font-poppins-medium items-center text-[12px] mt-4">
                                Already have an account?{' '}
                                <Link
                                    to="/"
                                    className="pl-1 font-merri-regular font-extrabold text-green-200"
                                >
                                    Log in
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </SideImage>
    );
};

export default ResetPasswordPage;
