import React, { FC } from 'react';
import '../Loader/loader.css';
import SvgIcon from '../Icon/SvgIcon';
import { IButtonProps } from '../common';

const getButtonStyles = (
    variant: string,
    color: string,
    disabled: boolean,
    isDrawerButton: boolean | undefined
) => {
    let backgroundColor: string;
    let fontColor: string;

    if (disabled) {
        fontColor = 'white';
        backgroundColor = '#8fb13180';
    } else if (variant === 'filled') {
        if (color === 'white') {
            fontColor = '#2E672F';
            backgroundColor = color;
        } else {
            fontColor = 'white';
            backgroundColor = color;
        }
    } else {
        backgroundColor = 'white';
        fontColor = color;
    }

    return {
        height: isDrawerButton ? '48px' : '32px',
        fontSize: isDrawerButton ? '16px' : '14px',
        fontWeight: 500,
        border: variant === 'outline' ? `1px solid ${fontColor}` : 'none',
        background: backgroundColor,
        color: fontColor,
        borderRadius: isDrawerButton ? '12px' : '6px',
        cursor: 'pointer',
    };
};

const ButtonContent: FC<{ loading: boolean; name: string }> = ({
    loading,
    name,
}) => {
    return (
        <div className="flex gap-1 justify-center">
            {loading && (
                <div className="spinner-container">
                    <div className="main-loader !h-5 !w-5" />
                </div>
            )}
            {name !== '' && (
                <div className="w-full flex text-nowrap font-merri-regular items-center justify-center">
                    {name}
                </div>
            )}
        </div>
    );
};

const PrimaryButton: FC<IButtonProps> = ({
    name,
    variant,
    color,
    type = 'button',
    className,
    loading = false,
    disabled = false,
    isDrawerButton,
    icon,
    iconPosition,
    iconStroke,
    ...rest
}) => {
    const buttonStyles = getButtonStyles(
        variant,
        color,
        disabled,
        isDrawerButton
    );

    return type === 'submit' ? (
        <button
            style={buttonStyles}
            type="submit"
            disabled={disabled}
            className={className}
            {...rest}
        >
            <ButtonContent loading={loading} name={name ?? ''} />
        </button>
    ) : (
        <div className="relative flex flex-wrap">
            {iconPosition === 'left' && (
                <SvgIcon
                    icon={icon}
                    style={{
                        position: 'absolute',
                        top: '44%',
                        padding: '0 0 0 10px',
                        width: '14px',
                        height: '15px',
                        transform: 'translateY(-50%)',
                        stroke: iconStroke,
                    }}
                />
            )}
            <button
                style={buttonStyles}
                type="button"
                disabled={disabled}
                className={className}
                {...rest}
            >
                <ButtonContent loading={loading} name={name ?? ''} />
            </button>
        </div>
    );
};

export default PrimaryButton;
