import React, { useEffect, useState } from 'react';
import { makePayment } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import FeatureLine from '../FeatureLine/FeatureLine';
import { BILLING_INVOICE_STATUS, IPlanDataProps } from '../../../Billing.types';
import { featureList, getCurrencySign } from '../../../Billing.utils';
import { PrimaryButton, SvgIcon } from '../../../../Common';

const PlanCard = ({
    price,
    isDefault,
    name,
    currency,
    unit,
    selectedPlan,
    setSelectedPlan,
    _id,
    getInvoiceData,
}: IPlanDataProps) => {
    const isSelected = selectedPlan === name;
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Set the default selected plan
    useEffect(() => {
        if (isDefault) {
            setSelectedPlan(name);
        }
    }, [isDefault, name, setSelectedPlan]);

    const buyPlan = async () => {
        const payload = {
            planId: _id,
            amount: price,
            currency,
            status: BILLING_INVOICE_STATUS.OUTSTANDING,
        };
        setIsLoading(true);
        try {
            const response = await makePayment(payload);
            showToast(response?.message, 'success');
            setSelectedPlan(name);
            getInvoiceData();
        } catch (err: any) {
            showToast(
                err?.errors?.[0]?.message || 'something went wrong',
                'error'
            );
        } finally {
            setIsLoading(false);
            setIsConfirmationModal(false);
        }
    };

    const handleOpenConfirmationModal = () => {
        if (!isSelected) {
            setIsConfirmationModal(true);
        }
    };

    return (
        <div
            className={`transition-all duration-200 ${
                isSelected
                    ? 'bg-[#449745] text-white'
                    : 'bg-[#F8FBEA] text-black'
            }  p-1 rounded-[10px] max-w-[360px]`}
        >
            {isConfirmationModal && (
                <ConfirmationModal
                    onConfirmation={buyPlan}
                    message={`Are you sure you want the ${name} plan for ${price}${getCurrencySign(
                        currency
                    )}?`}
                    isLoading={isLoading}
                    setIsModalOpen={setIsConfirmationModal}
                    onCancellation={() => {
                        setIsConfirmationModal(false);
                    }}
                />
            )}

            <div
                className={`${
                    isSelected
                        ? 'bg-[#449745] text-white'
                        : 'bg-white text-black'
                } rounded-[10px] px-[30px] pt-[26px] pb-8 space-y-4 transition-all duration-200`}
            >
                <div className="flex items-center justify-between h-6">
                    <div className="font-poppins-bold text-base leading-[20px]">
                        {name}
                    </div>
                    {isSelected && (
                        <SvgIcon
                            icon="CHECK_ICON_BILL"
                            className="stroke-white pt-1.5"
                        />
                    )}
                </div>
                <div className="flex gap-x-3 items-end">
                    <div className="font-poppins-bold text-[32px] leading-[26px]">
                        {price} {getCurrencySign(currency)}
                    </div>
                    <div className="text-xs">
                        /per{' '}
                        <span className="capitalize">
                            {unit?.toLowerCase()}
                        </span>
                    </div>
                </div>
                <PrimaryButton
                    color={isSelected ? 'white' : '#2E672F'}
                    variant="filled"
                    onClick={handleOpenConfirmationModal}
                    name={isSelected ? 'Current Plan' : 'Select Plan'}
                    className={`w-full !h-12 !rounded-xl !text-base ${
                        isSelected ? '!cursor-auto' : '!cursor-pointer'
                    }`}
                />
                <div className="space-y-2">
                    {featureList.map((feature, index) => (
                        <FeatureLine
                            key={index}
                            color={isSelected ? '#FFFFFF' : '#449745'}
                            feature={feature}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlanCard;
