import React, { useEffect, useState } from 'react';
import { PrimaryButton, SvgIcon } from '../../Common';
import PlanCard from './common/PlanCard/PlanCard';
import {
    IPlanData,
    IPlansProps,
    PLAN_DURATIONS_KEYS,
    PLAN_DURATIONS_LABEL,
} from '../Billing.types';

const Plans = ({ plans, invoiceData, getInvoiceData }: IPlansProps) => {
    const [duration, setDuration] = useState(PLAN_DURATIONS_KEYS.MONTH);
    const [filteredPlans, setFilteredPlans] = useState<IPlanData[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [plansCollapsed, setPlansCollapsed] = useState(false);

    useEffect(() => {
        const filteredData = plans.filter((ele) => ele.unit === duration);
        setFilteredPlans(filteredData);
    }, [plans, duration]);

    const handleDuration = (key: PLAN_DURATIONS_KEYS) => {
        setDuration(key);
        const filteredData = plans.filter((ele) => ele.unit === key);
        const defaultData = filteredData.find((ele) => ele.isDefault);
        setFilteredPlans(filteredData);
        setSelectedPlan(defaultData?.name || null);
    };

    const getButtonStyle = (key: PLAN_DURATIONS_KEYS) => {
        if (key === duration) {
            return '!rounded-full !px-4 !h-9 !text-xs font-merri-bold';
        }
        return '!bg-white !text-[#2E672F] !rounded-full !px-4 !h-9 !text-xs font-merri-bold';
    };

    return (
        <>
            <div className="flex gap-x-6 items-center justify-between my-4">
                <div className="flex items-center justify-between gap-x-6">
                    {invoiceData.length > 0 ? (
                        <button
                            type="button"
                            aria-label="colllapse"
                            onClick={() => setPlansCollapsed(!plansCollapsed)}
                            className="flex items-center px-0 gap-x-4 bg-transparent border-none cursor-pointer"
                        >
                            <SvgIcon
                                icon={
                                    plansCollapsed ? 'ARROW_UP' : 'ARROW_DOWN'
                                }
                                className="stroke-black pb-0.5"
                            />

                            <div className="font-poppins-bold text-base">
                                Price Plan
                            </div>
                        </button>
                    ) : (
                        <div className="font-poppins-bold text-base">
                            Price Plan
                        </div>
                    )}
                </div>
                <div className="flex bg-white w-fit rounded-full justify-between px-1 py-1 border border-solid border-[#00000014]">
                    <PrimaryButton
                        color="#2E672F"
                        variant="filled"
                        name={PLAN_DURATIONS_LABEL.MONTHLY}
                        onClick={() =>
                            handleDuration(PLAN_DURATIONS_KEYS.MONTH)
                        }
                        className={`${getButtonStyle(
                            PLAN_DURATIONS_KEYS.MONTH
                        )}`}
                    />
                    <PrimaryButton
                        color="#2E672F"
                        variant="filled"
                        name={PLAN_DURATIONS_LABEL.YEARLY}
                        onClick={() => handleDuration(PLAN_DURATIONS_KEYS.YEAR)}
                        className={`${getButtonStyle(
                            PLAN_DURATIONS_KEYS.YEAR
                        )}`}
                    />
                </div>
            </div>
            {!plansCollapsed && (
                <div className="bg-white rounded-3xl p-6">
                    <div className="flex justify-center items-center gap-8">
                        {filteredPlans.map((ele) => (
                            <PlanCard
                                key={ele._id}
                                {...ele}
                                selectedPlan={selectedPlan}
                                setSelectedPlan={setSelectedPlan}
                                getInvoiceData={getInvoiceData}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default Plans;
