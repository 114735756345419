import { SvgIcon } from '../../../../Common';

const FeatureLine = ({
    feature,
    color,
}: {
    feature: string;
    color: '#449745' | '#FFFFFF';
}) => {
    return (
        <div className="flex items-center gap-x-2 h-6">
            {color === '#FFFFFF' ? (
                <SvgIcon
                    icon="CHECK_ICON_BILL"
                    className="stroke-[#FFFFFF] pt-1.5"
                />
            ) : (
                <SvgIcon
                    icon="CHECK_ICON_BILL"
                    className="stroke-[#449745] pt-1.5"
                />
            )}
            <div className="text-xs" style={{ color }}>
                {feature}
            </div>
        </div>
    );
};

export default FeatureLine;
