import React, { useState } from 'react';
import { IColumnType } from 'components/Common/common';
import { payInvoice } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { getDateFormat } from 'data/utils/common';
import { BILLING_INVOICE_STATUS, IInvoiceData } from '../../Billing.types';
import { Dropdown, SvgIcon } from '../../../Common';
import { getCurrencySign, getStatus } from '../../Billing.utils';

export const PricingTableColumn = (
    getInvoiceData: () => void
): IColumnType<IInvoiceData>[] => {
    const [selectedRowId, setSelectedRowId] = useState<string>('0');
    const [loading, setLoading] = useState(false);

    const updateInvoiceStatus = async (invoiceId: string) => {
        setLoading(true);
        try {
            const response = await payInvoice(invoiceId, {
                status: BILLING_INVOICE_STATUS.PAID,
            });
            showToast(response.message, 'success');
            getInvoiceData();
        } catch (err: any) {
            showToast(err?.errors?.[0]?.msg ?? err.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDropdownClick = async (e: string, invoiceId: string) => {
        if (e === 'Pay Invoice') {
            await updateInvoiceStatus(invoiceId);
        } else if (e === 'View Invoice') {
            //     const url = `/invoice?invoiceId=${invoiceId}`;
            //     window.open(url, '_blank');
        }
        setSelectedRowId('0');
    };

    const getDropdownOptions = (data: IInvoiceData) => {
        const options = [];
        if (data.status !== BILLING_INVOICE_STATUS.PAID) {
            options.push({ title: 'Pay Invoice', id: 1, loading });
        }
        options.push({ title: 'View Invoice', id: 2 });
        return options;
    };

    return [
        {
            key: 'planName',
            title: (
                <div className="text-xs text-start tracking-tight font-poppins-medium">
                    Plan Name
                </div>
            ),
            width: 70,
        },
        {
            key: 'startDate',
            title: (
                <div className="text-xs text-start tracking-tight font-poppins-medium">
                    Start Date
                </div>
            ),
            width: 100,
            render: (_, data) => (
                <div className="text-start">
                    {getDateFormat(data?.startDate)}
                </div>
            ),
        },
        {
            key: 'endDate',
            title: (
                <div className="text-xs text-start tracking-tight font-poppins-medium">
                    End Date
                </div>
            ),
            width: 80,
            render: (_, data) => (
                <div className="text-start">{getDateFormat(data?.endDate)}</div>
            ),
        },
        {
            key: 'dueDate',
            title: (
                <div className="text-xs text-start tracking-tight font-poppins-medium">
                    Due Date
                </div>
            ),
            width: 70,
            render: (_, data) => (
                <div className="text-start">{getDateFormat(data?.dueDate)}</div>
            ),
        },
        {
            key: 'amount',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Amount
                    </div>
                </div>
            ),
            width: 70,
            render: (_, data) => (
                <div
                    className={`font-poppins-bold ${
                        data.status === 'PAID'
                            ? 'text-black'
                            : ' text-[#F56565]'
                    }`}
                >
                    {getCurrencySign(data.currency)}
                    {data.amount}
                </div>
            ),
        },
        {
            key: 'status',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Status
                    </div>
                </div>
            ),
            width: 70,
            render: (_, data) => (
                <div className="flex justify-center w-full">
                    <div
                        className={`w-[88px] justify-center flex items-center h-6 box-border font-poppins-bold text-xs rounded-md ${
                            data.status === BILLING_INVOICE_STATUS.PAID
                                ? ' bg-[#B6FFB6]'
                                : 'bg-[#FCA2C2]'
                        }`}
                    >
                        {getStatus(data.status)}
                    </div>
                </div>
            ),
        },
        {
            key: 'action',
            width: 10,
            render: (_, data) => (
                <div className="relative">
                    <button
                        className="bg-transparent border-none cursor-pointer"
                        onClick={() => {
                            if (selectedRowId === data._id) {
                                setSelectedRowId('0');
                                return;
                            }
                            setSelectedRowId(data._id);
                        }}
                        type="button"
                        aria-label="menu"
                    >
                        <SvgIcon icon="MENU_THREE_DOT" />
                    </button>
                    {data._id === selectedRowId && (
                        <div className="absolute -top-[51px] -left-36">
                            <Dropdown
                                submenus={getDropdownOptions(data)}
                                handleClick={(e) => {
                                    handleDropdownClick(e, data._id);
                                }}
                                dropdown={data._id === selectedRowId}
                            />
                        </div>
                    )}
                </div>
            ),
        },
    ];
};
