import React, { useState } from 'react';
import { resendVerificationLink } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { getEncryptedValue } from 'data/utils/common';
import { PrimaryButton, SvgIcon } from '../../../Common';

interface IVerifyEmailAddressProps {
    email: string;
}

const VerifyEmailAddress = ({ email }: IVerifyEmailAddressProps) => {
    const [loading, setLoading] = useState(false);

    const sendVerificationEmail = async () => {
        if (!process.env.REACT_APP_X_API_KEY) return;
        const xApiKey = getEncryptedValue(process.env.REACT_APP_X_API_KEY);
        if (!xApiKey) return;
        setLoading(true);
        try {
            const response = await resendVerificationLink(email, xApiKey);
            showToast(response?.message, 'success');
        } catch (err: any) {
            showToast(
                err?.errors?.[0]?.message || 'something went wrong',
                'error'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="gap-y-8 flex flex-col h-full items-center text-center justify-center">
            <SvgIcon icon="VERIFY_EMAIL" />
            <div className="space-y-4">
                <div className="text-[#2E672F] font-merri-bold text-2xl">
                    Verify your email address
                </div>
                <div className="text-base text-[#131119] leading-6">
                    A verification email has been sent to{' '}
                    <span className="text-[#8FB131]">{email}.</span> Please
                    click the link in the email to verify your address and
                    complete your Mozarto account setup.
                </div>
            </div>
            <PrimaryButton
                type="submit"
                isDrawerButton
                onClick={sendVerificationEmail}
                className="w-full"
                color="#2E672F"
                variant="filled"
                name={loading ? '' : 'Resend  verification link'}
                loading={loading}
                disabled={loading}
            />
        </div>
    );
};

export default VerifyEmailAddress;
