import React, { useEffect, useState } from 'react';
import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';
import { StepProps } from 'rc-steps/lib/Step';
import { PrimaryButton } from 'components/Common';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    cleoFormValidation,
    creditCardDetailsValidation,
    playerProfileValidation,
} from 'components/OnBoardCashier/OnBoardCashier.schema';
import {
    ICleoFormDetails,
    ICreditCardDetails,
    IMerchant,
    IPlayerType,
    IProfileDataType,
    PaymentMethodEnum,
} from 'components/OnBoardCashier/OnBoardCashier.type';
import { createMerchant, getMerchant, updateMerchant } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import {
    getComponent,
    stepItems,
} from 'components/OnBoardCashier/onBoardCashierUtill';
import { IMerchantUserPayload } from 'data/types/request';
import { getDecryptValue } from 'data/utils/common';
import { ZodType } from 'zod';
import MultiLevelDropdown from 'components/MultiLevelDropdown/MultiLevelDropdown';
import { useAuthContext } from 'context/Auth/AuthContext';
import './onBoardCashier.css';

const OnBoardCashier = () => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [profileData, setProfileData] = useState<IProfileDataType>({
        PlayerProfile: {},
        PaymentDetails: {},
    });
    const stepItemsLength = stepItems(currentStep).length;
    const { auth } = useAuthContext();
    const [paymentMethodValidator, setPaymentMethodValidator] = useState<
        ZodType<ICleoFormDetails> | ZodType<ICreditCardDetails>
    >(creditCardDetailsValidation);
    const [isEditMerchant, setIsEditMerchant] = useState(false);
    const [merchantId, setMerchantId] = useState('');
    const [loading, setLoading] = useState(false);

    const [controls] = useState<any>([
        useForm<IPlayerType>({
            resolver: zodResolver(playerProfileValidation),
            mode: 'onChange',
            defaultValues: {
                blocked: false,
                kycVerified: false,
            },
        }),
        useForm<ICreditCardDetails | ICleoFormDetails>({
            resolver: zodResolver(paymentMethodValidator),
            mode: 'onChange',
            defaultValues: {},
        }),
        useForm(),
        useForm(),
    ]);

    const merchantMid = auth?.selectedBrand?.mid || auth?.selectedCompany?.mid;

    useEffect(() => {
        if (controls[1].watch('pspType') === PaymentMethodEnum.CLEO) {
            setPaymentMethodValidator(cleoFormValidation);
        } else {
            setPaymentMethodValidator(creditCardDetailsValidation);
        }
    }, [controls[1].watch('pspType')]);

    const handleNextStep = controls[currentStep]?.handleSubmit(
        (formData: IPlayerType | ICreditCardDetails) => {
            setProfileData((prev) => ({
                ...prev,
                [Object.keys(prev)[currentStep]]: formData,
            }));
            if (!merchantMid) {
                showToast('Please select company first', 'error');
                return;
            }
            if (
                stepItems(currentStep)[currentStep].title === 'Payment Details'
            ) {
                handleSubmitForm({
                    ...profileData,
                    [Object.keys(profileData)[currentStep]]: formData,
                });
                return;
            }
            if (currentStep === stepItemsLength - 1) return;
            setCurrentStep(currentStep + 1);
        }
    );

    useEffect(() => {
        const userId = auth?.authUser?._id;

        if (!userId || !merchantMid) {
            controls[0]?.reset({
                blocked: false,
                kycVerified: false,
            });
            controls[1]?.reset({});
            return;
        }

        getMerchant({
            userId,
            merchantId: merchantMid,
        })
            .then((res: { details: IMerchant }) => {
                if (res?.details) {
                    const formattedDate = `${
                        res?.details?.card?.expiry?.month as string
                    }/${res?.details?.card?.expiry?.year as string}`;
                    controls[0]?.reset(res?.details);
                    controls[1]?.reset(res?.details);
                    controls[1]?.setValue('card.expiry', formattedDate);
                    controls[1]?.setValue(
                        'card.pan',
                        getDecryptValue(res?.details?.card?.pan)
                    );
                    setIsEditMerchant(true);
                    setMerchantId(res?.details?._id);
                } else {
                    setIsEditMerchant(false);
                    controls[0]?.reset({
                        blocked: false,
                        kycVerified: false,
                    });
                    controls[1]?.reset({});
                }
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    }, [auth?.authUser?._id, merchantMid]);

    const handleSubmitForm = (formData: IProfileDataType) => {
        setLoading(true);
        const { PaymentDetails, PlayerProfile } = formData;
        const { card } = PaymentDetails;
        const payload = {
            ...PlayerProfile,
            ...PaymentDetails,
            ...(card
                ? {
                      card: {
                          ...card,
                          expiry: {
                              month: card.expiry.split('/')[0],
                              year: card.expiry.split('/')[1],
                          },
                          billing_address: { ...PlayerProfile.address },
                      },
                  }
                : {}),
            postalCode: PlayerProfile.zipcode,
            userId: auth?.authUser?._id,
            country: PlayerProfile?.address?.country,
            brandId: auth?.selectedBrand?._id,
            companyId: auth?.selectedCompany?._id,
            merchantId: String(
                auth?.selectedBrand?.mid || auth?.selectedCompany?.mid
            ),
        };

        if (isEditMerchant) {
            updateMerchant(merchantId, payload)
                .then((res) => {
                    showToast(res?.message);
                    setLoading(false);
                    setCurrentStep(currentStep + 1);
                })
                .catch((err: any) => {
                    setLoading(false);
                    showToast(
                        err?.errors?.[0]?.message ||
                            err?.message ||
                            'something went wrong',
                        'error'
                    );
                });
        } else {
            createMerchant(payload as IMerchantUserPayload)
                .then((res) => {
                    showToast(res?.message);
                    setLoading(false);
                    setCurrentStep(currentStep + 1);
                    setIsEditMerchant(true);
                })
                .catch((err: any) => {
                    setLoading(false);
                    showToast(
                        err?.errors?.[0]?.message ||
                            err?.message ||
                            'something went wrong',
                        'error'
                    );
                });
        }
    };

    return (
        <div className="mx-auto my-5 w-[80rem]">
            <div className="mb-5 flex justify-end bg-white px-5 py-5">
                <MultiLevelDropdown />
            </div>
            <Steps
                labelPlacement="vertical"
                current={currentStep}
                onChange={() => {}}
                items={stepItems(currentStep) as StepProps[]}
            />
            {getComponent(currentStep, controls, handleNextStep)}
            <span className="flex items-center justify-end gap-x-5">
                {currentStep > 0 && currentStep !== stepItemsLength && (
                    <PrimaryButton
                        onClick={() => {
                            if (currentStep === 0) return;
                            setCurrentStep(currentStep - 1);
                        }}
                        type="button"
                        className="min-w-32 mt-5 !h-10 !text-lg !rounded-xl"
                        color="#2E672F"
                        variant="filled"
                        name="Back"
                    />
                )}
                {currentStep !== stepItemsLength - 1 && (
                    <PrimaryButton
                        onClick={handleNextStep}
                        type="submit"
                        className="min-w-32 mt-5 !h-10 !text-lg !rounded-xl"
                        color="#2E672F"
                        variant="filled"
                        name={loading ? '' : 'Next'}
                        loading={loading}
                        disabled={loading}
                    />
                )}
            </span>
        </div>
    );
};

export default OnBoardCashier;
