import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPassword } from 'components/AuthComponent';
import { resetPassword, verifyOtp } from 'services/api/api';
import { IPasswordData } from 'data/types/auth';
import { showToast } from 'data/utils/toast';
import { AuthenticationModal } from 'components/Modal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IVerifyOTP } from 'data/types/request';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import SideImage from 'components/Common/SideImage/SideImage';
import { formValidation } from './NewAdminPassword';

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { dispatch } = useAuthContext();
    const token = searchParams.get('token') ?? '';
    const [open, setOpen] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<IPasswordData>({
        resolver: zodResolver(formValidation),
        defaultValues: {},
    });

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            token,
            password: formData?.password || '',
        };
        setIsLoading(true);
        resetPassword(payload)
            .then((result) => {
                if (result.isSuccess) {
                    setOpen(true);
                    reset({});
                    showToast(
                        result.message,
                        result.isSuccess ? 'success' : 'error'
                    );
                }
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    const onVerifyOtp = () => {
        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };
        setIsOtpLoading(true);
        verifyOtp(payload, token)
            .then((result) => {
                localStorage.setItem('token', result.token || token);
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsOtpLoading(false);
            });
    };

    return (
        <SideImage>
            <ResetPassword
                handleSubmit={onSubmit}
                control={control}
                isLoading={isLoading}
                title="New Password"
                description="Enter your new password to begin again."
                btnName="Reset Password"
            />
            {open && (
                <AuthenticationModal
                    setIsOpen={setOpen}
                    handleVerifyOTP={onVerifyOtp}
                    setOtp={setOtp}
                    isOtpLoading={isOtpLoading}
                    otp={otp}
                    otpError={otpError}
                />
            )}
        </SideImage>
    );
};

export default ResetPasswordPage;
