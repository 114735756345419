import { Dispatch, SetStateAction } from 'react';

export interface IPlanData {
    _id: string;
    name: string;
    price: number;
    currency: string;
    unit: string;
    isDefault: boolean;
}

export interface IPaymentPayload {
    planId: string;
    amount: number;
    status: BILLING_INVOICE_STATUS;
    currency: string;
}

export interface IPlanDataProps extends IPlanData {
    setSelectedPlan: Dispatch<SetStateAction<string | null>>;
    selectedPlan: string | null;
    getInvoiceData: () => void;
}

export enum PLAN_DURATIONS_LABEL {
    MONTHLY = 'Monthly',
    YEARLY = 'Yearly',
}

export enum PLAN_DURATIONS_KEYS {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export interface IInvoiceData {
    _id: string;
    planId: string;
    userId: string;
    currency: string;
    status: BILLING_INVOICE_STATUS;
    planName: string;
    startDate: string;
    endDate: string;
    dueDate: string;
    amount: number;
}

export enum BILLING_INVOICE_STATUS {
    PAID = 'PAID',
    OUTSTANDING = 'OUTSTANDING',
}

export enum BILLING_INVOICE_STATUS_LABEL {
    PAID = 'Paid',
    OUTSTANDING = 'Outstanding',
}

export const invoiceFilterOptions = [
    {
        _id: BILLING_INVOICE_STATUS.PAID,
        name: BILLING_INVOICE_STATUS_LABEL.PAID,
    },
    {
        _id: BILLING_INVOICE_STATUS.OUTSTANDING,
        name: BILLING_INVOICE_STATUS_LABEL.OUTSTANDING,
    },
];

export interface IPricingAndBillingsProps {
    invoiceData: IInvoiceData[];
    getInvoiceData: () => void;
}

export interface IPlansProps {
    plans: IPlanData[];
    invoiceData: IInvoiceData[];
    getInvoiceData: () => void;
}
