import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';
import utc from 'dayjs/plugin/utc';
import {
    CardConstant,
    CardConstantLabel,
} from 'data/constants/payment.constants';
import {
    TransactionTypeEnum,
    TransactionTypeUppercaseEnum,
} from 'components/OnBoardCashier/OnBoardCashier.type';
import {
    TransactionStateEnum,
    TransactionStateEnumLabel,
} from 'components/RuleEngine/RuleEngine.type';
import { CurrenciesConstant, UserRoles } from 'data/constants/common.constants';

export const get = (obj: Record<string, any>, path: string, def?: any): any => {
    const fullPath = path
        .replace(/\[/g, '.')
        .replace(/]/g, '')
        .split('.')
        .filter(Boolean);

    if (obj == null) {
        return def || null;
    }

    function everyFunc(step: string): boolean | null {
        if (obj[step] !== null) {
            // eslint-disable-next-line no-param-reassign
            return !(step && (obj = obj[step]) === undefined);
        }
        return null;
    }

    return fullPath.every(everyFunc) ? obj : def;
};

export const getDateFormat = (dateValue: string) => {
    if (!dateValue) return '-';
    if (!dateValue.includes('T')) {
        return dateValue.replaceAll('-', '/');
    }
    let dateObj;

    // Check if the date is in the "DD-MM-YYYY" format
    if (/^\d{2}-\d{2}-\d{4}$/.test(dateValue)) {
        const [day, month, year] = dateValue.split('-');
        dateObj = new Date(`${year}-${month}-${day}`);
    } else {
        // Assume it's in ISO format
        dateObj = new Date(dateValue);
    }

    const date = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // getMonth() is zero-based
    const year = dateObj.getFullYear();
    return [
        month.toString().padStart(2, '0'),
        date.toString().padStart(2, '0'),
        year,
    ].join('/');
};

dayjs.extend(utc);

export const getDateFormats = (dateValue: string) => {
    const utcDate = dayjs.utc(dateValue);
    const utcDay = utcDate.format('DD');
    const utcMonth = utcDate.format('MM');
    const utcYear = utcDate.format('YYYY');

    return `${utcMonth}/${utcDay}/${utcYear}`;
};

export const getFirstDateFormat = (dateValue: string) => {
    const utcDate = dayjs.utc(dateValue);
    const utcDay = utcDate.format('DD');
    const utcMonth = utcDate.format('MM');
    const utcYear = utcDate.format('YYYY');

    return `${utcDay}/${utcMonth}/${utcYear}`;
};

export const formatNumber = (number: number, round?: number): string => {
    if (!number || Number.isNaN(number)) {
        return '0.00';
    }
    const roundDecimal = round ?? 2;
    return number.toFixed(roundDecimal);
};

export const isAdmin = (role: UserRoles | null): boolean =>
    role === UserRoles.ADMIN;
export const isUser = (role: UserRoles | null): boolean =>
    role === UserRoles.USER;
export const isSuperAdmin = (role: UserRoles | null): boolean =>
    role === UserRoles.SUPER_ADMIN;

export const getCurrencySign = (currency: CurrenciesConstant | 'EURO') => {
    switch (currency) {
        case CurrenciesConstant.CLP:
            return 'CLP';
        case CurrenciesConstant.EURO:
        case CurrenciesConstant.EUR:
            return '€';
        case CurrenciesConstant.USD:
            return '$';
        default:
            return '';
    }
};

export const getDecryptValue = (value: string): string | null => {
    if (!value) {
        console.warn('No value provided for decryption');
        return null;
    }

    const encryptionKey = process.env.REACT_APP_ENCRYPTED_KEY;

    if (!encryptionKey) {
        console.error('Encryption key is missing in environment variables');
        return null;
    }

    try {
        const bytes = CryptoJS.AES.decrypt(value, encryptionKey);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedValue) {
            console.warn(
                'Failed to decrypt value: result is empty or incorrect'
            );
            return null;
        }

        return decryptedValue;
    } catch (error) {
        console.error('Error decrypting value:', error);
        return null;
    }
};

export const getListName = (list: string) => {
    switch (list) {
        case 'DEPOSIT':
            return 'Deposit';
        case 'WITHDRAWAL':
            return 'Withdrawal';
        case CardConstant.MASTERCARD:
            return CardConstantLabel.MASTERCARD;
        case CardConstant.VISA:
            return CardConstantLabel.VISA;
        case CardConstant.AMERICAN_EXPRESS:
            return CardConstantLabel.AMERICAN_EXPRESS;
        case CardConstant.DINERS_CLUB:
            return CardConstantLabel.DINERS_CLUB;
        case CardConstant.DISCOVER:
            return CardConstantLabel.DISCOVER;
        case CardConstant.JCB:
            return CardConstantLabel.JCB;
        case CardConstant.UNIONPAY:
            return CardConstantLabel.UNIONPAY;
        case CardConstant.MAESTRO:
            return CardConstantLabel.MAESTRO;
        case CardConstant.ELO:
            return CardConstantLabel.ELO;
        case CardConstant.MIR:
            return CardConstantLabel.MIR;
        case CardConstant.HIPER:
            return CardConstantLabel.HIPER;
        case CardConstant.HIPERCARD:
            return CardConstantLabel.HIPERCARD;
        case TransactionTypeUppercaseEnum.CREDIT_CARD_DEPOSIT:
            return TransactionTypeEnum.CREDIT_CARD_DEPOSIT;
        case TransactionTypeUppercaseEnum.CREDIT_CARD_WITHDRAWALS:
            return TransactionTypeEnum.CREDIT_CARD_WITHDRAWALS;
        case TransactionTypeUppercaseEnum.WEB_REDIRECT_DEPOSIT:
            return TransactionTypeEnum.WEB_REDIRECT_DEPOSIT;
        case TransactionTypeUppercaseEnum.WEB_REDIRECT_WITHDRAWALS:
            return TransactionTypeEnum.WEB_REDIRECT_WITHDRAWALS;
        case TransactionTypeUppercaseEnum.LOCAL_BANK_WITHDRAWALS:
            return TransactionTypeEnum.LOCAL_BANK_WITHDRAWALS;
        case TransactionTypeUppercaseEnum.BANK_WITHDRAWAL:
            return TransactionTypeEnum.BANK_WITHDRAWAL;
        case TransactionStateEnumLabel.ALL_PAYMENTS:
            return TransactionStateEnum.ALL_PAYMENTS;
        case TransactionStateEnumLabel.SUSPENDED:
            return TransactionStateEnum.SUSPENDED;
        case TransactionStateEnumLabel.PENDING:
            return TransactionStateEnum.PENDING;
        case TransactionStateEnumLabel.SUCCESS:
            return TransactionStateEnum.SUCCESS;
        case TransactionStateEnumLabel.EXPIRED:
            return TransactionStateEnum.EXPIRED;
        case TransactionStateEnumLabel.FAILED:
            return TransactionStateEnum.FAILED;
        case TransactionStateEnumLabel.ERROR:
            return TransactionStateEnum.ERROR;
        case TransactionStateEnumLabel.CAPTURED:
            return TransactionStateEnum.CAPTURED;
        default:
            return list;
    }
};

export const formatCamelCase = (key: string) => {
    return key
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

export const formatValue = (value: unknown): string | undefined => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        return Object.entries(value)
            .map(([key, val]) => `${formatCamelCase(key)}: ${val as string}`)
            .join(', ');
    }
    if (Array.isArray(value)) {
        return value?.join(', ');
    }
    return value as string | undefined;
};

interface IGetLabelColor {
    isError: boolean;
    isHovered: boolean;
    value: string | undefined;
    isFocused: boolean;
}

export const getLabelColor = ({
    isError,
    isHovered,
    value,
    isFocused,
}: IGetLabelColor) => {
    if (isError) {
        return 'text-red-200';
    }
    if (isHovered || value) {
        return 'text-black';
    }
    if (!isFocused) {
        return 'text-gray-500';
    }
    return 'text-black';
};

export const getEncryptedValue = (value: string) => {
    if (process.env.REACT_APP_ENCRYPTED_KEY) {
        return CryptoJS.AES.encrypt(
            value,
            process.env.REACT_APP_ENCRYPTED_KEY
        ).toString();
    }
};
