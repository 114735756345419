import { IIcons } from 'data/common';
import { z, ZodType } from 'zod';
import {
    ConfigurationType,
    IBriteConfigurationType,
    ICleoConfigurationType,
} from './configuration.type';

export const getPSPNameByValue = (value: string) =>
    ProvidersList.find((provider) => value === provider?.value)?.name ?? '-';

export const ProvidersList: Array<{
    name: string;
    value: string;
    logo?: keyof IIcons;
}> = [
    { name: 'Cleo', value: 'CLEO', logo: 'CLEO' },
    { name: 'Trust Payments', value: 'TRUST', logo: 'TRUST_PAYMENT' },
    { name: 'PayModum', value: 'PAYMODUM', logo: 'PAYMODUM' },
    { name: 'Brite', value: 'BRITE', logo: 'PAYMODUM' },
];

export const cleoFormSchemaValidation: ZodType<ICleoConfigurationType> = z
    .object({
        accountName: z
            .string({ required_error: 'Please enter your account name' })
            .trim()
            .min(1, 'Please enter your account name')
            .max(32, 'Only 32 characters are allowed')
            .refine((value) => value !== 'Account Name', {
                message: 'Please change the account name',
            }),
        apiKey: z
            .string({ required_error: 'Please enter your api key' })
            .trim()
            .min(1, 'Please enter your api key'),
        customerExternalKeyForCleo: z
            .string({
                required_error: 'Please enter your customer external key',
            })
            .optional()
            .nullable(),
        comment: z
            .string({ required_error: 'Please enter your comment' })
            .optional()
            .nullable(),
    })
    .required();

export const formSchemaValidation: ZodType<ConfigurationType> = z
    .object({
        accountName: z
            .string({ required_error: 'Please enter your account name' })
            .trim()
            .min(1, 'Please enter your account name')
            .max(32, 'Only 32 characters are allowed')
            .refine((value) => value !== 'Account Name', {
                message: 'Please change the account name',
            }),
        comment: z
            .string({ required_error: 'Please enter your comment' })
            .optional()
            .nullable(),
        username: z
            .string({ required_error: 'Please enter your username' })
            .trim()
            .min(1, 'Please enter your username'),
        password: z
            .string({ required_error: 'Please enter your password' })
            .trim()
            .min(1, 'Please enter your password'),
    })
    .required();

export const briteFormSchemaValidation: ZodType<IBriteConfigurationType> = z
    .object({
        accountName: z
            .string({ required_error: 'Please enter your account name' })
            .trim()
            .min(1, 'Please enter your account name')
            .max(32, 'Only 32 characters are allowed')
            .refine((value) => value !== 'Account Name', {
                message: 'Please change the account name',
            }),
        comment: z
            .string({ required_error: 'Please enter your comment' })
            .optional()
            .nullable(),
        secret: z
            .string({ required_error: 'Please enter your secret key' })
            .trim()
            .min(1, 'Please enter your secret key'),
        public_key: z
            .string({ required_error: 'Please enter your public key' })
            .trim()
            .min(1, 'Please enter your public key'),
    })
    .required();
