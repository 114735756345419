import React, { useEffect, useState } from 'react';
import Plans from 'components/Billing/Plans/Plans';
import PricingAndBillings from 'components/Billing/PricingAndBillings/PricingAndBillings';
import { getBillingInformation, getPlans } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IPlanData, IInvoiceData } from 'components/Billing/Billing.types';
import { Loader } from 'components/Common';

const Billing = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState<IInvoiceData[]>([]);
    const [plans, setPlans] = useState<IPlanData[]>([]);

    const fetchInvoiceData = async () => {
        setIsLoading(true);
        try {
            const response = await getBillingInformation();
            setInvoiceData(response?.billingsInvoices || []);
        } catch (err: any) {
            showToast(err?.errors?.[0]?.msg ?? err.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPlansData = async () => {
        setIsLoading(true);
        try {
            const response = await getPlans();
            const sortedData = (response?.plans || []).sort(
                (a: { price: number }, b: { price: number }) =>
                    a.price - b.price
            );
            setPlans(sortedData);
        } catch (err: any) {
            showToast(err?.errors?.[0]?.msg ?? err.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchInvoiceData();
        fetchPlansData();
    }, []);

    return isLoading ? (
        <div className="p-5">
            <Loader />
        </div>
    ) : (
        <div className="p-8 space-y-9">
            <PricingAndBillings
                invoiceData={invoiceData}
                getInvoiceData={fetchInvoiceData}
            />
            <Plans
                plans={plans}
                invoiceData={invoiceData}
                getInvoiceData={fetchInvoiceData}
            />
        </div>
    );
};

export default Billing;
