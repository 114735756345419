import React from 'react';
import './Dropdown.css';
import { DropdownProps } from '../common';
import { SvgIcon } from '../index';

const Dropdown = ({
    submenus,
    dropdown,
    handleClick,
    isExportModal = false,
    className,
}: DropdownProps) => (
    <div
        className={` ${
            dropdown
                ? `flex flex-col absolute rounded border Dropdown ${
                      className as string
                  }`
                : 'none'
        }`}
    >
        {submenus.map((submenu: any) => {
            if (submenu?.loading) {
                return (
                    <div className="spinner-container flex justify-center w-full h-9">
                        <div className="main-loader !h-3 !w-3" />
                    </div>
                );
            }
            return (
                <button
                    type="button"
                    key={submenu.title}
                    className="menu-items border-none h-9 cursor-pointer"
                    onClick={() => handleClick(submenu.title)}
                >
                    {submenu.icon && (
                        <SvgIcon className="h-[15px]" icon={submenu.icon} />
                    )}
                    <div
                        className={`sub-menu cursor-pointer ${
                            isExportModal
                                ? 'font-poppins-medium text-[10px]'
                                : ''
                        }`}
                    >
                        {submenu.title}
                    </div>
                </button>
            );
        })}
    </div>
);

export default Dropdown;
