import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IIcons } from 'data/common';
import { useLocation } from 'react-router';
import SvgIcon from '../Icon/SvgIcon';
import { IMenubarProps } from '../common';

const Menubar = ({ menuItem }: IMenubarProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isActiveIndex, setIsActiveIndex] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [path, setPath] = useState<string | undefined>(location.pathname);
    const [hoveredIndex, setHoveredIndex] = useState<string | null>(null);

    useEffect(() => {
        if (location.pathname.includes('admin')) {
            setIsActiveIndex('admin');
        }
        if (location.pathname.includes('workflows')) {
            setIsActiveIndex('workflows');
        }
        if (location.pathname.includes('payment')) {
            setIsActiveIndex('payment');
        }
        if (location.pathname.includes('operations')) {
            setIsActiveIndex('operations');
        }
        if (location.pathname.includes('payment-methods')) {
            setIsActiveIndex('payment-methods');
        }
        if (location.pathname.includes('dashboard')) {
            setIsActiveIndex('dashboard');
        }
        if (location.pathname.includes('audit')) {
            setIsActiveIndex('audit');
        }
        if (location.pathname.includes('cashier')) {
            setIsActiveIndex('cashier');
        }
        if (location.pathname.includes('billing')) {
            setIsOpen(false);
            setIsActiveIndex('billing');
        }
    }, [location.pathname]);

    const handleMenuClick = (v: any) => {
        if (!v.subMenu) return;

        if (path === v.path) {
            setIsOpen(!isOpen);
        } else {
            setIsOpen(true);
        }
        setPath(v.path);
        setIsActiveIndex(v.path?.split('/')[1] as string);
    };

    const getStrokeClass = (vPath: string): string => {
        if (
            hoveredIndex === vPath ||
            location.pathname === vPath ||
            location.pathname.includes(vPath)
        ) {
            return isOpen && location.pathname.includes(vPath)
                ? 'stroke-2 stroke-[#2E672F] hidden'
                : 'stroke-2 stroke-white';
        }
        return 'stroke-2 stroke-[#2E672F]';
    };

    return (
        <div className="flex h-full flex-col justify-between px-2">
            <div>
                {(menuItem || []).map((v: any) => (
                    <div
                        key={v.title}
                        className={
                            !v?.path ? 'pointer-events-none opacity-50' : ''
                        }
                    >
                        <Link
                            to={v?.path as string}
                            onMouseEnter={() => setHoveredIndex(v.path)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            className="!decoration-0 text-green-800 !border-b-0 hover:text-white !h-[40px]"
                        >
                            <div
                                key={v.id}
                                className={classNames(
                                    'flex flex-col hover:bg-green-100 rounded-lg focus:bg-green-100 mt-1',
                                    (location.pathname === v.path ||
                                        location.pathname.includes(
                                            v?.path as string
                                        )) &&
                                        isActiveIndex ===
                                            v.path?.split('/')[1] &&
                                        'bg-green-100'
                                )}
                            >
                                <button
                                    type="button"
                                    className="flex items-center justify-between bg-transparent border-none"
                                    key={v.title}
                                    onClick={() => handleMenuClick(v)}
                                >
                                    <div className="ml-3 flex items-center">
                                        <SvgIcon
                                            icon={
                                                hoveredIndex === v.path ||
                                                location.pathname === v.path ||
                                                location.pathname.includes(
                                                    v?.path as string
                                                )
                                                    ? `${
                                                          v.icon as keyof IIcons
                                                      }_FILL`
                                                    : v.icon
                                            }
                                            className="fill-green-100 flex justify-center text-center h-6"
                                        />

                                        <div
                                            className={classNames(
                                                'ml-4 text-base text-start leading-5 font-merri-bold tracking-tighter py-2 ',
                                                location.pathname === v.path ||
                                                    location.pathname.includes(
                                                        v.path
                                                    ) ||
                                                    isActiveIndex ===
                                                        v.path?.split('/')[1] ||
                                                    v.path === hoveredIndex
                                                    ? 'text-white'
                                                    : ''
                                            )}
                                        >
                                            {v?.title}
                                        </div>
                                    </div>
                                    <div className="mr-4 pb-1">
                                        {v.subMenu.length > 0 && (
                                            <SvgIcon
                                                className={getStrokeClass(
                                                    v.path
                                                )}
                                                icon="ARROW_DOWN"
                                            />
                                        )}
                                        {v.subMenu.length > 0 &&
                                            isOpen &&
                                            ((location.pathname.includes(
                                                v?.path as string
                                            ) &&
                                                isActiveIndex ===
                                                    v.path?.split('/')[1]) ||
                                                (location.pathname === v.path &&
                                                    isActiveIndex ===
                                                        v.path?.split(
                                                            '/'
                                                        )[1])) && (
                                                <SvgIcon
                                                    icon="ARROW_UP"
                                                    className="stroke-white"
                                                />
                                            )}
                                    </div>
                                </button>
                            </div>
                        </Link>
                        {isActiveIndex === v.path?.split('/')[1] &&
                            isOpen &&
                            v.subMenu && (
                                <div className="mx-4 mt-1 ml-4 border border-y-0 border-r-0 border-solid border-[#9ED79F]">
                                    {(v?.subMenu || []).map(
                                        (d: any, index: number) => (
                                            <div
                                                className="py-1 pl-2"
                                                key={d?.title}
                                            >
                                                <Link to={d?.path}>
                                                    <div
                                                        className={classNames(
                                                            'ml-1 text-green-100 text-xs leading-4 font-merri-bold tracking-tighter',
                                                            location.pathname ===
                                                                d.path ||
                                                                (index === 0 &&
                                                                    (location.pathname ===
                                                                        '/admin' ||
                                                                        location.pathname ===
                                                                            '/payment-methods' ||
                                                                        location.pathname ===
                                                                            '/cashier' ||
                                                                        location.pathname ===
                                                                            '/operations'))
                                                                ? 'text-green-200'
                                                                : ''
                                                        )}
                                                    >
                                                        {d?.title}
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                    </div>
                ))}
            </div>

            <button
                className={`${
                    location.pathname.includes('acceptable-usage-policy')
                        ? 'bg-[#2E672F] text-white'
                        : 'bg-[#F3FAF3] text-[#2E672F]'
                }  border-none rounded-lg w-full cursor-pointer mt-4`}
                type="button"
                onClick={() => {
                    navigate('/acceptable-usage-policy');
                    setIsOpen(false);
                    setIsActiveIndex('acceptable-usage-policy');
                }}
            >
                <div className="flex justify-between items-center p-1.5">
                    <div className="font-merri-bold">
                        Acceptable Usage Policy
                    </div>
                    <SvgIcon
                        icon={`${
                            location.pathname.includes(
                                'acceptable-usage-policy'
                            )
                                ? 'ARROW_LEFT_WHITE'
                                : 'ARROW_LEFT_FILL'
                        }`}
                    />
                </div>
            </button>
        </div>
    );
};
export default Menubar;
