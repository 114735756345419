import { UserRoles } from 'data/constants/common.constants';
import { IGetAllPageAreasData } from 'data/types/response';
import { IBrandPermissions, IGroup, IPagesAccess, IUser } from 'data/common';
import { ActionMap } from '../context.helpers';

export interface IAppContext {
    authUser: IUser | null;
    isLoggedIn: boolean | null;
    selectedCompany?: any;
    selectedBrand?: any;
    role: UserRoles | null;
    permissions: IPagesAccess[];
    brandPermissions: IBrandPermissions[];
    isAdmin: boolean;
    isSuperAdmin: boolean;
    isUser: boolean;
    activeGroup?: any;
    companiesAndBrands?: any;
    pageAreaPermissions?: IGetAllPageAreasData[] | undefined;
    groups?: IGroup[] | null;
    brandsList?: any;
    isRegisterUser?: boolean;
}

export const AppInitialState: IAppContext = {
    authUser: null,
    permissions: [],
    brandPermissions: [],
    isLoggedIn: null,
    role: null,
    selectedCompany: null,
    selectedBrand: null,
    isAdmin: false,
    isSuperAdmin: false,
    isUser: false,
    activeGroup: null,
    companiesAndBrands: [],
    pageAreaPermissions: [],
    groups: [],
    brandsList: [],
};

export enum AppActionsEnum {
    SET_CURRENT_USER = 'SET_CURRENT_USER',
    SET_BRAND = 'SET_BRAND',
    SET_COMPANY = 'SET_COMPANY',
    SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP',
    SET_COMPANY_AND_BRANDS = 'SET_COMPANY_AND_BRANDS',
    SET_PAGE_AREA_PERMISSIONS = 'SET_PAGE_AREA_PERMISSIONS',
    SET_GROUPS = 'SET_GROUPS',
    SET_BRANDS_LIST = 'SET_BRANDS_LIST',
}

export type AppActionsPayload = {
    [AppActionsEnum.SET_CURRENT_USER]: {
        authUser: IUser | null;
        permissions: IPagesAccess[];
        brandPermissions: IBrandPermissions[];
        isLoggedIn: boolean | null;
        role: UserRoles | null;
        isRegisterUser?: boolean;
    };
    [AppActionsEnum.SET_COMPANY]: {
        selectedCompany: any;
    };
    [AppActionsEnum.SET_BRAND]: {
        selectedBrand: any;
    };
    [AppActionsEnum.SET_ACTIVE_GROUP]: {
        activeGroup: any;
    };

    [AppActionsEnum.SET_COMPANY_AND_BRANDS]: {
        companiesAndBrands: any;
    };
    [AppActionsEnum.SET_PAGE_AREA_PERMISSIONS]: {
        pageAreaPermissions: any;
    };
    [AppActionsEnum.SET_GROUPS]: {
        groups: any;
    };
    [AppActionsEnum.SET_BRANDS_LIST]: {
        brandsList: any;
    };
};

export type AppActions =
    ActionMap<AppActionsPayload>[keyof ActionMap<AppActionsPayload>];
