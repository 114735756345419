import { ReactNode } from 'react';
import { onboardingGraphic, mozartoFullLogo } from 'assets';
import { SvgIcon } from '../index';

const SideImage = ({ children }: { children: ReactNode }) => {
    return (
        <div className="flex justify-center w-[100vw]">
            <div className="flex p-20 w-[100vw] max-w-[1440px] h-[100vh] box-border gap-x-20">
                <div className="relative w-full min-w-[400px] h-[calc(100vh-160px)] max-w-[600px]">
                    <img
                        className="w-full h-full"
                        src={onboardingGraphic}
                        alt="resigter"
                    />
                    <img
                        src={mozartoFullLogo}
                        className="w-64 h-12 left-12 top-12 absolute"
                        alt="logo"
                    />
                    <div className="absolute max-w-[504px] top-48 left-12 text-white">
                        <div className="text-5xl font-urbanist-bold">
                            Connected By
                            <span className="text-[#BDD864]"> Clicks</span>,
                            <div className="font-urbanist-bold">Not Code.</div>
                        </div>
                        <div className="text-2xl pt-5 font-poppins-regular">
                            Unlock the Power of Mozarto for Your Business.
                        </div>
                    </div>
                    <SvgIcon
                        icon="SIDE_IMAGE_ARROW_ICON"
                        className="absolute right-10 bottom-6"
                    />
                </div>
                <div className="min-w-[400px] max-w-[520px] w-full px-10 h-[calc(100vh-160px)] overflow-auto">
                    <div className="flex items-center min-h-full">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideImage;
